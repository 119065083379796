<template>
  <div>
    <router-link v-if="webenv === 'cwm'" to="/manage">
      <v-img src="@assets/cwm_logo.svg" contain height="64" class="my-1 mx-2" />
    </router-link>
    <router-link v-else-if="webenv === 'knox services'" to="/manage">
      <v-img
        src="@assets/logo/windtre_logotype.svg"
        contain
        height="64"
        class="my-1 mx-2 mb-4"
      />
    </router-link>
    <router-link v-else to="/manage">
      <v-img
        src="@assets/ennova_logotype.svg"
        contain
        height="48"
        class="my-3 mx-2"
      />
    </router-link>
  </div>
</template>

<script>
import utils from '@mixins/utils.js'

export default {
  name: 'NavDrawerLogo',
  mixins: [utils],
}
</script>
