export default [
  {
    title: 'Principale',
    items: [
      // {
      //   title: 'Cruscotto',
      //   icon: 'mdi-monitor-dashboard',
      //   to: '/manage',
      //   loggedOnly: true,
      //   section: 'dashboard',
      //   action: 'read',
      // },
      {
        title: 'Clienti',
        icon: 'mdi-account-multiple-check',
        group: 'customers',
        // section: 'customers',
        items: [
          {
            title: 'Ricerca',
            to: 'customers_search',
          },
          {
            title: 'Crea Nuovo',
            to: 'customers_create',
          },
          {
            title: 'Utenti',
            to: 'customers_users_search',
          },
        ],
      },

      {
        title: 'Ticket',
        icon: 'mdi-face-agent',
        group: 'tickets',
        section: 'tickets',
        items: [
          {
            title: 'Ricerca',
            to: 'tickets_search',
          },
          {
            title: 'Crea Nuovo',
            to: 'tickets_create',
            action: 'create',
            denyAction: 'create_custom'
          },
          {
            title: 'Richiesta SIM',
            to: 'tickets_create_params',
            action: 'request_sims',
            params: {
              key: 'sims',
            },
          },
          {
            title: 'Richiesta Terminali',
            to: 'tickets_create_params',
            action: 'request_devices',
            params: {
              key: 'devices',
            },
          },
          {
            title: 'Richiesta Assistenza',
            to: 'tickets_create_params',
            action: 'request_support',
            params: {
              key: 'support',
            },
          },
        ],
      },
      {
        title: 'Assets',
        icon: 'mdi-devices',
        group: 'customers.assets',
        items: [
          {
            title: 'Ricerca',
            to: 'assets_search',
          },
          {
            title: 'Crea Nuovo',
            to: 'assets_create',
          },
          {
            title: 'Catalogo Prodotti',
            to: 'products_search',
          },
          // {
          //   title: 'Nuovo Prodotto',
          //   to: 'products_create',
          // },
        ],
      },
      /* {
        title: 'Analisi Traffico',
        icon: 'cash-multiple',
        group: 'customers.assets',
        items: [
          {
            title: 'Visualizza',
            to: 'cdc_iframe',
          },
        ],
      }, */
      {
        title: 'Sottoscrizioni',
        icon: 'mdi-playlist-check',
        group: 'plans_subscriptions',
        items: [
          {
            title: 'Ricerca',
            to: 'plans_subscriptions',
          },
          {
            title: 'Ordini TIM Digital Store',
            to: 'timopen_request',
            section: 'timopen_requests',
            action: 'search',
          },
          // {
          //   title: 'Ordini Digital Store',
          //   to: '/manage/customers/create',
          //   action: 'create',
          // },
          // {
          //   title: 'Ordini AppDirect',
          //   to: '/manage/customers/create',
          //   action: 'create',
          // },
        ],
      },

      {
        title: 'Reporting',
        icon: 'mdi-file-chart',
        group: 'reports',
        items: [
          {
            title: 'Ricerca',
            to: 'reports',
          },
        ],
      },
      {
        title: 'Sessioni Supporto',
        icon: 'mdi-face-agent',
        group: 'sessions',
        section: 'sessions',
        items: [
          {
            title: 'Da Gestire',
            to: 'sessions_pending',
            action: 'search',
          },
          {
            title: 'Consulta Storico',
            to: 'sessions_search',
            action: 'report',
          },
        ],
      },
      {
        title: 'Agente Virtuale',
        icon: 'mdi-robot',
        group: 'virtual_agents',
        section: 'virtual_agents',
        items: [
          {
            title: 'Dashboard',
            to: 'virtualagents_dashboard',
            action: 'search',
          },
          {
            title: 'Ricerca sessioni',
            to: 'virtualagents_sessions_index',
            action: 'search',
          },
          {
            title: 'Crea Sessione',
            to: 'virtualagents_create_quick',
            action: 'create',
          },
          {
            title: 'Mail preconfigurate',
            to: 'virtualagents_search_mails',
            section: 'virtual_agents_mails',
            action: 'search',
          },
          {
            title: 'Allegati',
            to: 'virtualagents_search_attachments',
            section: 'virtual_agents_mails',
            action: 'search',
          },
          {
            title: 'Impostazioni',
            to: 'virtualagents_search_presets',
            section: 'virtual_agents_presets',
            action: 'search',
          },
          // {
          //   title: 'Crea sessione',
          //   to: 'virtualagents_create_session',
          //   action: 'create',
          // },
        ],
      },
    ],
  },
  {
    title: 'Amministrazione',
    items: [
      {
        title: 'Progetti',
        icon: 'mdi-handshake',
        group: 'admin.projects',
        items: [
          {
            title: 'Ricerca',
            to: 'admin.projects',
          },
          {
            title: 'Crea Nuovo',
            to: 'admin.projects_create',
          },
        ],
      },
      {
        title: 'Opzioni',
        icon: 'mdi-cog',
        items: [
          {
            title: 'Agende',
            to: 'agendas_search',
          },
          {
            title: 'Piani',
            to: 'plans_search',
          },
          {
            title: 'Attività Ticket',
            to: 'ticket_activities_search',
          },
          {
            title: 'Stati Ticket',
            to: 'ticket_statuses_search',
          },
          {
            title: 'Esiti Ticket',
            to: 'tickets_resolutions_search',
          },
          {
            title: 'Import da File',
            to: 'uploaded_files_progress_search',
          },
          {
            title: 'Link rapidi',
            to: 'nav_entries',
          },
          {
            title: 'Configurazioni',
            to: 'settings',
          },
        ],
      },
      {
        title: 'Gestione Utenze',
        icon: 'mdi-account-group',
        items: [
          {
            title: 'Ricerca',
            to: 'admin.users',
          },
          {
            title: 'Crea Nuovo',
            to: 'users_create',
          },
          {
            title: 'Gestione permessi',
            to: 'users_permissions',
          },
        ],
      },
    ],
  },

  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          title: 'DEV',
          items: [
            {
              title: 'Pagina di sviluppo',
              icon: 'mdi-logout-variant',
              to: 'dev',
              loggedOnly: false,
            },
          ],
        },
      ]
    : []),
  {
    title: 'Sessione',
    items: [
      {
        title: 'Logout',
        icon: 'mdi-logout-variant',
        to: 'logout',
        loggedOnly: false,
      },
      // {
      //   title: 'Test',
      //   icon: 'mdi-logout-variant',
      //   to: '/manage/test',
      //   loggedOnly: false,
      // },
    ],
  },
]
