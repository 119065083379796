<template>
  <span></span>
</template>

<script>
import { mapState } from 'vuex'
import routes from '@router/routes'
import { flattenTree } from '@utils/helper-functions'

/*
This component operates some checks that are useful in development
*/
export default {
  name: 'PermissionsRoutesCheck',
  computed: {
    ...mapState('sections', {
      sections: (state) => state.list.map((sec) => sec.value),
    }),
    ...mapState('operations', {
      operations: (state) => state.list.map((op) => op.value),
    }),
  },
  mounted() {
    // All these checks are useful for dev
    if (process.env.NODE_ENV === 'development') {
      // Run only if data is available
      if (this.sections.length) {
        if (this.operations.length) {
          // Get a flattened version if the tree
          const flattenedRoutes = flattenTree(routes)

          // Returns true for routes that are arbitrary
          // or carry special functionality
          const shouldBeExcluded = (route) => {
            let should = false
            if (
              ['base', 'profile', 'login', 'recovery', 'logout'].includes(
                route.name
              )
            )
              should = true
            if (route.path === '/') should = true
            return should
          }

          flattenedRoutes.reduce((acc, route) => {
            // Exclude routes that are arbitrary
            if (!shouldBeExcluded(route)) {
              // Permission tests need to be performed on management routes only
              if (route.path.includes('manage')) {
                if (!route.meta.section)
                  console.warn(route, 'Has no section prop')
                if (!route.meta.action)
                  console.warn(route, 'Has no action prop')
              }
              if (!route.name) console.warn(route, 'Route has no name')
            }

            if (acc[route.name])
              console.warn(route, 'Route has a duplicated name')
            acc[route.name] = route
            return acc
          }, {})
        }
      }
    }
  },
}
</script>
