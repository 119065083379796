<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    overflow
    :mini-variant="!drawer"
    :permanent="!$vuetify.breakpoint.mobile"
    :expand-on-hover="false && !drawer"
    :class="dark ? 'drawerShadow-dark' : 'drawerShadow'"
  >
    <template v-slot:prepend>
      <v-row v-if="!$vuetify.breakpoint.mobile">
        <v-col class="py-8 px-6">
          <v-app-bar-nav-icon @click.stop="toggleDrawer"
        /></v-col>
      </v-row>
      <NavDrawerLogo v-else />
    </template>

    <PermissionsRoutesCheck />

    <v-list v-if="currentCustomerId" dense nav class="pb-0">
      <v-list-item
        :to="{ name: 'customers_detail', params: { id: currentCustomerId } }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-briefcase-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profilo aziendale</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list
      v-for="section in parsedMenu.slice(0, parsedMenu.length - 1)"
      :key="section.title"
      dense
      nav
    >
      <!--      <v-subheader v-if="false && activeSectionItems(section.items).length">
        <v-scroll-x-reverse-transition>
          <span v-if="drawer">{{ section.title }}</span>
        </v-scroll-x-reverse-transition>
      </v-subheader>-->

      <div v-for="item in activeSectionItems(section.items)" :key="item.title">
        <v-list-group
          v-if="item.items && activeSectionSubitems(item).length"
          no-action
          :group="item.section"
        >
          <!-- questo template crea il menù ad espansione nel caso in cui il drawer sia chiuso -->
          <template v-slot:prependIcon>
            <v-menu offset-x>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                      v-bind="attrs"
                      v-on="!drawer && { ...tooltip, ...menu }"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </template>
              <v-list width="240">
                <v-subheader>
                  <strong>{{ item.title }}</strong>
                </v-subheader>
                <v-divider />
                <v-list-item
                  v-for="subItem in activeSectionSubitems(item)"
                  :key="subItem.title"
                  :to="{ name: subItem.to }"
                  color="primary"
                  exact
                >
                  <v-list-item-content class="body-2">
                    {{ subItem.title }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <!-- activator del list group -->
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- /activator del list group -->

          <!-- lista delle sottovoci quando il drawer è visibile -->
          <v-list-item
            v-for="subItem in activeSectionSubitems(item)"
            :key="subItem.title"
            :to="{
              name: subItem.to,
              props: subItem.props,
              params: subItem.params,
              query: subItem.query,
            }"
            exact
          >
            <v-list-item-content class="body-2">
              {{ subItem.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>

    <v-list v-if="canUser('nav_entries', 'read') && links.length" dense nav>
      <div>
        <v-list-group prepend-icon="mdi-link" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Link rapidi</v-list-item-title>
            </v-list-item-content>
          </template>

          <NavEntry
            v-for="link in links"
            :key="link.id"
            style="padding-left: 5px"
            v-bind="link"
          />
        </v-list-group>
      </div>
    </v-list>

    <v-list
      v-for="section in parsedMenu.slice(parsedMenu.length - 1)"
      :key="section.title"
      dense
      nav
    >
      <div v-for="item in section.items" :key="item.title">
        <v-list-group
          v-if="item.items"
          :prepend-icon="item.icon"
          no-action
          :group="item.section"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="{
              name: subItem.to,
              props: subItem.props,
              params: subItem.params,
              query: subItem.query,
            }"
            exact
          >
            <v-list-item-content class="body-2">
              {{ subItem.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="!item.items"
          :prepend-icon="item.icon"
          :to="{
            name: item.to,
            props: item.props,
            params: item.params,
            query: item.query,
          }"
          class="mb-1"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>

    <template v-slot:append>
      <v-divider />
      <v-list dense nav>
        <v-list-item>
          <v-list-item-icon class="mr-2">
            <v-icon @click="setDarkMode(!dark)">
              {{ dark ? 'mdi-brightness-4' : 'mdi-brightness-6' }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>v{{ version }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" small v-on="on">
                  mdi-information-variant
                </v-icon>
              </template>
              <span>
                Versione: <b>{{ version }}</b
                ><br />
                Compilata:
                <b>{{ buildDate }}</b>
                <br />
                Hash: <strong>{{ buildHash }}@{{ buildBranch }}</strong>
              </span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import PermissionsRoutesCheck from '@components/structure/permissions-routes-check.vue'
import { authComputed } from '@state/helpers'
import menu from '@router/menu'
import routes from '@router/routes'
import { mapMutations, mapState } from 'vuex'
import { version } from '@/package.json'
import { deepFilter, flattenTree } from '@utils/helper-functions'
import NavDrawerLogo from '@components/structure/NavDrawerLogo.vue'
import moment from 'moment'
import NavEntry from '@components/nav_entries/NavEntry.vue'

/*
  Make a map like {
    routeName: route,
    routeName2: route2
  }
*/
const mappedRoutes = flattenTree(routes).reduce(
  (acc, route) => ({ ...acc, [route.name]: route }),
  {}
)

export default {
  components: { NavDrawerLogo, PermissionsRoutesCheck, NavEntry },
  data() {
    return {
      parsedMenu: [],
      version,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('navEntries', {
      links: 'list',
    }),
    drawer: {
      get() {
        return this.$store.state.ui.drawer
      },
      set(value) {
        return this.setDrawer(value)
      },
    },

    section: {
      get() {
        return this.$store.state.ui.section
      },
      set(value) {
        return this.setSection(value)
      },
    },
    dark: {
      get() {
        return this.$store.state.ui.dark_mode
      },
      set(value) {
        return this.setDarkMode(value)
      },
    },
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    /* eslint-disable no-undef */
    buildDate() {
      if (typeof __BUILD_DATE__ !== 'undefined')
        return moment(__BUILD_DATE__).format('DD/MM/YYYY HH:mm')

      return moment().format('DD/MM/YYYY HH:mm')
    },
    buildHash() {
      if (typeof __BUILD_COMMIT_HASH__ !== 'undefined')
        return String(__BUILD_COMMIT_HASH__).substr(0, 7)

      return 'dev'
    },
    buildBranch() {
      if (typeof __BUILD_BRANCH__ !== 'undefined')
        return String(__BUILD_BRANCH__)

      return 'dev'
    },
    /* eslint-enable no-undef */
  },
  watch: {
    dark: {
      immediate: true,
      handler(value) {
        this.$vuetify.theme.dark = value
      },
    },
  },
  mounted() {
    this.parsedMenu = deepFilter(menu, (item) => {
      const targetRoute = mappedRoutes[item.to]

      // WIP routes are hidden with a warning
      if (!targetRoute) {
        if (this.isDev) {
          console.warn(item, `Is not linked to any route so it's been hidden.`)
        }
        return false
      } else {
        const { section, action, does } = this.routeDoesRequirePermission(
          targetRoute
        )

        if (does) {
          // If the route requires permissions and is correctly configured check permission
          const can =
            this.canUser(section, action) && this.canUser(section, 'menu')
          return can
        } else {
          return true
        }
      }
    })
  },
  methods: {
    ...mapMutations('ui', {
      toggleDrawer: 'TOGGLE_DRAWER',
      setDrawer: 'SET_DRAWER',
      setSection: 'SET_SECTION',
      setDarkMode: 'SET_DARK_MODE',
    }),
    activeSectionItems(items) {
      return items.filter(
        (item) =>
          !item.section || (item.section && this.canUserSee(item.section))
      )
    },
    activeSectionSubitems(item) {
      return item.items.filter(
        (subitem) =>
          !item.section ||
          !subitem.action ||
          (this.canUser(subitem.section || item.section, subitem.action) &&
            !this.denyUser(subitem.section || item.section, subitem.denyAction))
      )
    },
    routeDoesRequirePermission(route) {
      const { section, action } = route.meta || {}
      if (section || action) return { does: true, section, action }
      else return { does: false }
    },
  },
}
</script>

<style type="text/css">
.drawerShadow {
  box-shadow: 0 2px 10px -1px rgba(85, 85, 85, 0.08),
    0 1px 10px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}
.drawerShadow-dark {
  box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.08),
    0 1px 10px 0 rgba(0, 0, 0, 0.06), 0 1px 30px 0 rgba(0, 0, 0, 0.03) !important;
}
</style>
